import * as React from 'react';
import type { PageProps } from 'gatsby';
import { graphql, Link } from 'gatsby';
import { required } from '@cometjs/core';
import { mapUnion } from '@cometjs/graphql-utils';

import Layout from '../components/Layout';

type Props = PageProps<GatsbyTypes.SupportPartnerPageQuery>;

export const query = graphql`
  query SupportPartnerPage(
    $partnerPublicId: String!
  ) {
    marketplacePartner(
      publicId: { eq: $partnerPublicId }
    ) {
      id
      name
      miniId
      item {
        __typename
        ... on MarketplaceMiniappPreRegistration {
          slug
          linkedContent {
            id
            prismicId
          }
        }
      }
    }
  }
`;

const SupportPartnerPage: React.FC<Props> = ({
  data: {
    marketplacePartner,
  },
}) => {
  required(marketplacePartner);

  return (
    <Layout>
      <header>
        <h1>Partner ID: {marketplacePartner.name} ({marketplacePartner.miniId})</h1>
      </header>
      <main>
        <ul>
          {mapUnion(marketplacePartner.item, {
            MarketplaceMiniapp: null,
            MarketplaceMiniappPreRegistration: preRegistration => (
              preRegistration.linkedContent.map(content => {
                const target = `/kr/partner-pre/${preRegistration.slug}/${content.prismicId}/`;
                return (
                  <li key={content.id}>
                    <Link to={target}>{target}</Link>
                  </li>
                );
              })
            ),
          })}
        </ul>
      </main>
    </Layout>
  );
};

export default SupportPartnerPage;
